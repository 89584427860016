import { useState } from 'react';
import { Button, Row, Col, Modal, Input } from 'antd';
import { FiArrowLeftCircle, FiArrowRight, FiArrowRightCircle, FiArrowUp, FiPlus } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export const Home = ({ setShowModal }) => {

	const navigate = useNavigate();

	const reviews = [
			{
					name:   "Michael Broadbridge",
					company: "Fun Cases",
					image:  "reviews/fun-cases.jpeg",
					review: "Service has been outstanding in producing and maintaining our social media ads. They didn’t just hit our target ROAS, they smashed it by a mile."
			},
			{
					name:   "Chris Jakeways",
					company: "Vanaways",
					image:  "reviews/vanaways.jpeg",
					review: "These guys know their stuff! Finding Bigg has made a massive difference to our business and helped to make us one of the country's largest suppliers of Vans."
			},
			{
					name:   "Kirsty McNeil",
					company: "Western Global",
					image:  "reviews/western-global.jpeg",
					review: "We have worked with these guys since 2019, at first with the UK side of our business, however they now partner with us for our US growth market."
			},
			{
					name:   "Maxim Lyashco",
					company: "ICS",
					image:  "reviews/ics.jpeg",
					review: "I'm more than happy not only with the quality of their work but also with their customer support. The communication from the team has been excellent."
			},
			
	]
	const [ reviewNumber, setReviewNumber ] = useState(0)

	const changeReview = (i) => {

			let newReviewNumber = reviewNumber + i

			if (newReviewNumber > reviews.length - 1) {
					newReviewNumber = 0
			} else if (newReviewNumber < 0) {
					newReviewNumber = reviews.length -1
			}

			setReviewNumber(newReviewNumber)

			
	}

	const reviewItem = () => {

			const r = reviews[reviewNumber]

			return (
					<div className="section grey" style={{paddingTop: 100, paddingBottom: 100}}>
							<div className="container thin">
									<div className="review">
											<h2 style={{textAlign: 'center'}}>What our clients say</h2>
											<Row gutter={15} display="flex" justify={"center"}>
													<Col sm={8}>
															<div style={{textAlign: 'center'}}>
																	<div className="review-image">
																			<img src={`/images/${r.image}`} alt="review"/>
																	</div>
																	<div className="reviewArrow" onClick={() => changeReview(-1)}>
																			<FiArrowLeftCircle size={50} color={"#fff"} fill={"#000"} />
																	</div>
																	<div className="reviewArrow"onClick={() => changeReview(1)}>
																			<FiArrowRightCircle size={50} color={"#fff"} fill={"#000"} />
																	</div>
																	
															</div>
													</Col>
													<Col sm={15} offset={1}>
															
															
															
															<div className="review-name">
																	{r.name}
															</div>
															<div className="review-company">
																	{r.company}
															</div>
															<p className="reviewText">
																	“{r.review}”
															</p>
															<div className="quoteMark">
																	"
															</div>
													
													</Col>
													<Col sm={4}>
															
													</Col>
											</Row>
									</div>
							</div>
					</div>
			)
	}


	const banner = () => {

		return (
			<div id="banner">
				<div className="container">
					<Row gutter={15} align="middle">
						<Col sm={14}>
							{/* <div className="heroPreText">
								Be Curious. Be different. Ask Why.
							</div> */}
							<div className="heroText">
								<h1>Entrepreneurship Fueled by Curiosity and Crafted by Your Narrative.</h1>
								<h4>The world of entrepreneurship is where curiosity is the compass, guiding you to not just walk paths laid by others but to create a narrative uniquely yours.</h4>
							</div>
							
						</Col>
						<Col sm={10}>
							<img src="/images/me-banner.png" alt="John Geater Banner"/>
						</Col>
					</Row>                        
				</div>
			</div>
        )
    }

	const CompanyCard = ({name, description, image, year}) => {

		return (
			<div className="result">
				<div className="content">
						<div className="name">{name}</div>
						<div className="number">
								<div className="no">{year}</div>
						</div>
						<div className="description">{description}</div>
				</div>
				<div className="image" style={{backgroundImage: `URL(${image})`}}>
						
				</div>
		</div>
		)
	}

	const logos = () => {

		return (
			<Row>
				<Col xs={0} lg={24}>
			
			<div className="section grey">
				<div className="container">
					<Row gutter={100} type="flex" justify="center" align="middle">
						<Col xs={6}>
							<img alt="" style={{width: '100%'}} src="/images/forbes.png"/>
						</Col>
						<Col xs={6}>
							<img alt="" style={{width: '100%'}} src="/images/Entrepreneur.png"/>
						</Col>
						<Col xs={6}>
							<img alt="" style={{width: '100%'}} src="/images/medium.png"/>
						</Col>
						<Col xs={6}>
							<img alt="" style={{width: '100%'}} src="/images/youtube.png"/>
						</Col>
					</Row>
				</div>
			</div>
			</Col>
			</Row>
		)
	}
	const business = () => {

			return (
					<div className="section" id="business">
							<div className="container">
									<h3 style={{marginTop: 100, textAlign: 'center', maxWidth: 800, margin: '0 auto'}}>Business and investment journey</h3>
									<div style={{textAlign: 'center'}}>
									<svg width="43" height="87" viewBox="0 0 43 87" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M18.5852 83.5761C20.3686 78.7666 21.6538 74.2105 22.1291 69.9117C22.3061 68.1051 24.9291 68.3288 24.7517 70.1356C24.394 73.1268 23.6626 76.3079 22.6825 79.6158C27.401 75.0272 30.3083 70.2695 31.7162 65.3389C33.3646 58.7258 31.8937 52.4536 27.3036 46.5226C26.9643 46.1059 26.6433 45.7027 26.337 45.3178C25.508 44.2766 24.786 43.3696 24.0986 42.6945L22.296 44.081C16.95 48.0533 12.1544 50.0286 7.97101 49.8196C6.22303 49.774 5.03599 49.5987 4.22243 49.233C2.53393 48.689 1.52927 47.6406 1.02084 46.0272C0.384419 43.8548 0.685983 41.985 2.1748 40.2901C3.22862 38.973 4.72064 37.9007 6.96324 37.0701C10.7629 35.6022 15.4425 35.2462 18.2566 36.1528C20.3835 36.9418 22.3244 37.9811 24.0795 39.2714C27.6828 36.1871 30.7845 32.7343 33.5094 28.8493C37.4108 23.2725 39.5676 18.2729 40.2286 13.7231C40.8879 8.86221 39.433 5.39052 35.9876 3.2453C34.4218 2.32668 35.7827 0.0729231 37.3483 0.991618C41.7335 3.75025 43.6305 8.08893 42.7273 14.0103C42.1308 18.9953 39.7272 24.4329 35.6397 30.2604C32.8535 34.3325 29.6272 37.8488 26.0872 41.0569C26.7745 41.7319 27.4962 42.6386 28.3249 43.6796C28.6314 44.0647 28.9526 44.4682 29.292 44.8851C34.4486 51.6199 35.986 58.6383 34.2167 65.9371C32.9348 71.1152 29.8416 76.1237 25.062 80.8998C28.553 80.0572 32.2369 80.2091 36.1134 81.3542C37.6773 81.9618 36.8795 84.3966 35.2542 83.9766C30.0012 82.2844 24.948 82.8305 20.1579 85.7393C19.3497 86.3075 18.7868 86.126 18.4081 85.3827C17.968 84.8268 18.0277 84.3281 18.5238 83.7632L18.5852 83.5761ZM21.1821 41.5204L22.4612 40.5498C21.1116 39.4834 19.4426 38.7393 17.71 38.187C15.528 37.3855 11.298 37.6273 7.90431 38.8781C6.17588 39.5993 4.83231 40.4434 4.00176 41.3449C3.23572 42.373 3.0472 43.5853 3.43612 44.9815C3.63062 45.6797 4.20913 46.1822 4.97916 46.4276C5.55668 46.6117 6.77523 46.7881 8.24979 46.8341C11.7764 47.1101 16.1295 45.211 21.1821 41.5204Z" fill="#1A78FE"></path>
									</svg>
									</div>
							<div className="resultSection">
									<Row gutter={30}>
											<Col xs={24} sm={12} lg={8}>
												<CompanyCard name="TechIt" year={1998} description="IT support services for residents and businesses across Bristol" image={"https://www.planitplus.net/JobProfileImages/228.jpg"}/>
											</Col>
											<Col xs={24} sm={12} lg={8}>
												<CompanyCard name="Bigg" year={2006} description="Full service marketing agency supporting small businesses across the UK" image={"https://lh3.googleusercontent.com/p/AF1QipNmFtJgtnUj-GJRLlcoKPNSGi2aWUGpDDNuw_x8=s1360-w1360-h1020"}/>
											</Col>
											<Col xs={24} sm={12} lg={8}>
												<CompanyCard name="Social Exposure" year={2011} description="Social Media Management consultancy and agency merged into Bigg in 2016" image={"https://www.minddevelopmentanddesign.com/wp-content/uploads/2021/09/people-on-their-phones-with-social-engagement-icons-floating-above-them.jpg"}/>
											</Col>
											<Col xs={24} sm={12} lg={8}>
												<CompanyCard name="Willow Lettings" year={2012} description="Residential letting agency servicing landlords across the Bristol region" image={"https://bark-user-data.s3.eu-west-1.amazonaws.com/users/6451809/5ee795671e348-1592235367.png"}/>
											</Col>
											<Col xs={24} sm={12} lg={8}>
												<CompanyCard name="Discount Stores" year={2013} description="Retail Operation specialising in discounted products on the high street" image={"https://dmrqkbkq8el9i.cloudfront.net/Pictures/2000xAny/2/6/7/174267_Poundland-multi-price-web_crop.jpg"}/>
											</Col>
											<Col xs={24} sm={12} lg={8}>
												<CompanyCard name="Reptile Direct" year={2015} description="Ecommerce business supplying food and accessories for owners of reptiles" image={"https://animalcoursesdirect.co.uk/uploads/course/C550-32-BANNER-Reptile-Studies-img2.jpg"}/>
											</Col>
											<Col xs={24} sm={12} lg={8}>
												<CompanyCard name="TrademarkReg" year={2015} description="Worldwide trademark consultancy and registration service" image={"https://admin.netlawman.com/uploads/article/original/trademarks-introduction.jpg"}/>
											</Col>
											<Col xs={24} sm={12} lg={8}>
												<CompanyCard name="Floreli" year={2017} description="Sameday online florists operating across Bristol and surrounding areas" image={"https://www.swfloral.co.uk/upload/mt/swfd58/products/lg_20159091-florist-choice-selection.jpg"}/>
											</Col>
											<Col xs={24} sm={12} lg={8}>
												<CompanyCard name="Freight Docs" year={2020} description="Customs documentation service for the freight and logistics sector" image={"https://www.aerofrt.com/wp-content/uploads/2021/12/BLOG-1.jpg"}/>
											</Col>
											<Col xs={24} sm={12} lg={8}>
												<CompanyCard name="Clean Bees" year={2024} description="Cleaning with a difference. Purchased in 2024 as a platform for future acquisitions" image={"https://images.squarespace-cdn.com/content/v1/5b150c4e5b409b2554db9508/81494ca0-0711-48d6-b111-53a8f69ff5aa/Origin+image+1.jpg?format=1500w"}/>
											</Col>
											<Col xs={24} sm={12} lg={8}>
												<CompanyCard name="KS Cleaning" year={2024} description="Delivering high quality cleaning services across the South West since 1980" image={"/images/kscleaning.jpg"}/>
											</Col>
											
									</Row>
									</div>
							</div>
					</div>
					
			)
	}

	const about = () => {

			return (
					<div className="section" id="about">
							<div className="container">
									
									<div className="about" style={{marginTop: 100}}>
											<Row gutter={15} type="flex" justify={"center"} align="middle">
													<Col xs={0} lg={12}>
													{/* <img alt=""  style={{width: '70%'}} src="images/me-2.png"/> */}
													<img style={{width: '70%'}} src="images/me-2.png"/>

													</Col>
													<Col lg={12}>
														<h3 style={{marginTop: 100, margin: '0 auto'}}>
																Who is John Geater?
														</h3>
														<div style={{width: 200, marginTop: 30, borderBottom: '10px #FDA602 solid'}}>

														</div>
															<p style={{marginTop: 30}}>
																John, born in 1977 in Bristol, embodies a trailblazer in the world of entrepreneurship. Armed with a relentless drive and a keen eye for opportunities, he embarked on a journey that would see him establish a highly successful digital marketing agency. Through his innate curiosity and self-taught programming skills, John recognized the potential of the burgeoning online market early on. With a strategic mindset and a knack for innovation, he transformed his agency into a powerhouse, navigating the complexities of the digital landscape with finesse.
															</p>
															<p>
																Fuelled by his entrepreneurial spirit, John's success extended beyond the digital realm. Leveraging his expertise and insights, he ventured into diverse sectors, investing in and establishing a portfolio of businesses. From technology startups to ventures in logistics and beyond, John's ventures reflect his unwavering commitment to exploring new horizons and creating lasting impact across multiple industries. With each endeavor, he continues to push boundaries, embodying the ethos of a visionary leader and serial entrepreneur.
															</p>
															
													</Col>
											</Row>
									</div>

									

							</div>
							
					</div>
			)
	}

	const books = () => {

		return (
			<div className="section">
				<div className="container">
					<div className="about" style={{marginTop: 50}}>
						<Row gutter={15} type="flex" justify={"center"} align="middle">
							<Col sm={24}>
								<h3 style={{width: 400, paddingBottom: 20, borderBottom: '10px #FDA602 solid', marginTop: 100, textAlign: 'center', margin: '0 auto'}}>
									Published Books
								</h3>				
							</Col>
						</Row>

						<div className="books">
						<Row gutter={30}>
							<Col sm={6}>
								<img alt="" style={{width: '100%'}} src="https://danielpriestley.com/wp-content/uploads/2022/03/entrepreneur-revolution-book.png"/>
								<Button type="primary">Coming Soon</Button>
							</Col>
							<Col sm={6}>
								<img alt="" style={{width: '100%'}} src="https://danielpriestley.com/wp-content/uploads/2022/03/entrepreneur-revolution-book.png"/>
								<Button type="primary">Coming Soon</Button>
							</Col>
							<Col sm={6}>
								<img alt="" style={{width: '100%'}} src="https://danielpriestley.com/wp-content/uploads/2022/03/entrepreneur-revolution-book.png"/>
								<Button type="primary">Coming Soon</Button>
							</Col>
							<Col sm={6}>
								<img alt="" style={{width: '100%'}} src="https://danielpriestley.com/wp-content/uploads/2022/03/entrepreneur-revolution-book.png"/>
								<Button type="primary">Coming Soon</Button>
							</Col>
						</Row>
						</div>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className="page">
			{ banner() }
			{ logos() }
			{ about() }
			{ business() }
		</div>
	)

	const readytogrow = () => {

			return (
					<>
					<div className="section">
							<div className="container">
									<div style={{paddingTop: 0, paddingBottom: 100}}>
											<h2 style={{textAlign: 'center'}}>
													Ready to start growing<br/>your business?
											</h2>
											<p style={{margin: '0 auto', maxWidth: 800, textAlign: 'center'}}>
													We can't wait to start working with you, click below to start building your package by telling
													us a little about who you're targeting, where they are and how much you want to invest.
											</p>
											<div style={{textAlign: 'center', marginTop: 50}}>
											<Button onClick={() => setShowModal(true)} style={{
																	display: 'inline-flex',
																	alignItems: 'center',
																	justifyContent: 'center',
															}} type="primary">Build your package <FiArrowRight style={{marginLeft: 5}}/> </Button>
													<Button type="blackoutline">Get in touch</Button>
											</div>
									</div>
							</div>
					</div>
					</>
			)
	}

        return (
		<>
		<Helmet>
                        <meta charSet="utf-8" />
                        <title>John Geater</title>
			<meta name="description" content="Entrepreneurship Fueled by Curiosity and Crafted by Your Narrative. The world of entrepreneurship is where curiosity is the compass, guiding you to not just walk paths laid by others but to create a narrative uniquely yours." />
                </Helmet>
		
                <div className="page">
                        { banner() }
			{ logos() }
                        { about() }
			{ business() }
			{/* { books() } */}
                </div>
		</>
        )

}