import './App.css';
import { Header } from './Modules/Templates/Header';
import { Home } from './Modules/Home/Home';
import { Footer } from './Modules/Templates/Footer';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
 
  return (
    <div className="App">
      <BrowserRouter>
      <Header/>
        <Routes>
            <Route path="/*" element={<Home  />} />
        </Routes>
      <Footer/>
      </BrowserRouter>

      
    </div>
  );
}

export default App;
