import { Row, Col } from 'antd'

export const Header = () => {

        const scrollToSection = (id) => {
		const element = document.getElementById(id);
		if (element) {
		  element.scrollIntoView({ behavior: 'smooth' });
		}
	};

        return (
                <div id="header">
                        <div className="container">
                        <Row gutter={15}>
                                <Col xs={24} lg={8}>
                                        <div className="logo">
                                                JOHN GEATER
                                        </div>
                                        
                                </Col>
                                <Col xs={0} lg={16} style={{textAlign: 'right'}}>
                                        <div className="menu">
                                                <ul>
                                                        <li onClick={() => scrollToSection('about')}>About Me</li>
                                                        <li onClick={() => scrollToSection('business')}>Business</li>
                                                        <li onClick={() => window.open('https://medium.com/@JohnGeater')}>Blog</li>
                                                        <li onClick={() => scrollToSection('footer')}>Contact</li>
                                                </ul>
                                        </div>
                                </Col>
                        </Row>
                        </div>

                        
                </div>
        )
}